
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import MessageList from "@/components/List/MessagesList.vue";
import { Pagination } from "ant-design-vue";

@Component({
  name: "AnnouncementAll",
  components: {
    MessageList,
    [Pagination.name]: Pagination,
  },
  filters: {
    //局部过滤器
  },
})
export default class AnnouncementAll extends Vue {
  public loading: boolean = false;
  public total: number = 0;
  public current: number = 1;
  public pageSize: number = 8;
  public listData: any[] = [];

  public visible: boolean = false;
  public showAnnouncement: any = {};

  // 切换页面
  handleChange(page: number): void {
    this.current = page;
    // this.queryAnnouncementList();
  }
  // 分页条数切换
  pageSizeChange(current: number, size: number): void {
    this.pageSize = size;
    // this.queryAnnouncementList();
  }
  // 查看详情
  seeDetails(value: any): void {
    this.showAnnouncement.title = value.title;
    this.showAnnouncement.startDate = value.startDate;
    this.showAnnouncement.endDate = value.endDate;
    this.showAnnouncement.content = value.content;
    this.visible = true;
  }
  // 获取公告列表
  // queryAnnouncementList(): void {
  //   this.$apis
  //     .bulletinPageList({
  //       isPage: 1,
  //       pageNum: this.current,
  //       pageSize: this.pageSize,
  //     })
  //     .then((res: any) => {
  //       if (res.code === "200") {
  //         this.listData = res.data.list;
  //         this.total = res.data.total;
  //       }
  //     });
  // }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  // created(): void {
  //   this.queryAnnouncementList();
  // }
}
